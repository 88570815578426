/* You can add global styles to this file, and also import other style files */
.edit-recipe span.dropdown-btn {
  border-radius: 15px 15px 15px 15px !important;
  border: 1px solid #e3e3e3 !important;
}

.confirm-button {
  background-color: #b2d571 !important;
  border-radius: 30px;
}

.cancel-button {
  background-color: #f36f48 !important;
  border-radius: 30px;
}

.multiselect-dropdown .dropdown-list {
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .predefined-tag {
    margin-bottom: -15px;
  }
}

.working-hours-row {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.working-hours-day {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  width: 7rem;
  font-size: 1.1rem;
}

.working-hours-row .time-range-wrapper {
  margin-left: 1rem;
}

.working-hours-switch .custom-control-label {
  margin-bottom: 0 !important;
  font-size: 1rem !important;
  cursor: pointer !important;
  color: #000;
}

.working-hours-switch {
  cursor: pointer !important;
  margin-right: 0.5rem;
}

.required-field {
  position: relative;
}

.required-field::after {
  content: "*";
  color: red;
  font-size: 1rem;
}

.btn-link {
  padding: 5px !important;
  margin: 0 !important;
}

.ngb-tp-input {
  padding: 5px !important;
}

.ngb-tp-meridian .btn {
  padding: 3px 5px !important;
}

.ngb-tp-input-container input {
  border-radius: 4px !important;
}

.ngb-tp-input-container {
  width: 2em !important;
}

.ngb-tp-spacer {
  width: 0.5em !important;
}